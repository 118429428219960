import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import MenuCards from "../components/menuCards"
import AffPes from "../components/pes2021cta"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="container">
        <h1>PES 2021 MYCLUB GUIDE</h1>
        <p style={{ paddingTop: "1rem" }}>
          Welcome to the new and improved PES 2021 myClub Guide! In here, you
          will find everything you need to conquer PES 2021 myClub: how to scout
          top players, earn GP, train talent, build strong teams and sign
          managers.
        </p>
        <p>
          If you're new to the game and don't know{" "}
          <Link to="/first-steps/">what to do first, start here</Link>. It will
          show you how to avoid common mistakes and make as much profit as
          possible.
        </p>
        <p>Otherwise, find some of the most popular sections below.</p>
        <MenuCards />
        <p>
          Didn't find what you were looking for? Have a look at the Menu{" "}
          <FontAwesomeIcon icon="bars" /> for the full list.
        </p>
        <p>
          Below you can find a brief explanation of what myClub is, as well as
          what's new in this year's update. There's also an introduction to this
          guide.
        </p>
      </div>
      <AffPes />
      <nav id="toc">
        <div className="container">
          <span>Table of Contents</span>
          <ul>
            <li>
              <AnchorLink href="#whatispes" offset="70">
                What is PES myClub?
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#pes2021" offset="70">
                What you get in PES 2021
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#whatsnew" offset="70">
                What's new in PES 2021?
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#why-season-update" offset="70">
                Why a Season Update? What about next-gen?
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#about" offset="70">
                About this Guide
              </AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <h2 id="whatispes">What is PES myClub?</h2>
        <p>
          myClub is, alongside Master League, the game’s most popular mode. The
          goal is to build your super team, using whichever players you like for
          each position.{" "}
        </p>
        <p>
          If you are coming from FIFA, this will look similar to FIFA’s Ultimate
          Team (despite <Link to="/coming-from-fifa/">its differences</Link>).
        </p>
        <p>
          You compete online or VS COM for game money (known as{" "}
          <Link to="/coins-gp/">GP</Link>) to have better players/managers and
          renew your team’s contracts. The more you play, the more experienced
          your players will be - which will result in higher stats.
        </p>
        <p>
          Players have{" "}
          <Link to="/player-form-condition-rating/">
            weekly condition rating updates
          </Link>{" "}
          - if they performed well in real life, they have increased likelihood
          of performing well here (known as "Form" in Pro Evolution Soccer).
        </p>
        <p>
          This makes things more challenging and interesting, and allows for
          some diversity in the teams you face.
        </p>
        <h2 id="pes2021">What you get in PES 2021</h2>
        <p>
          PES 2021 was released on September 15th, 2020 and is available for
          PS4, Xbox One and PC (Steam).
        </p>
        <p>
          If you played either eFootball PES 2020 or eFootball PES 2020 LITE,
          you can get 20% off by buying a Club Edition (digital version) now.
          You should use the in-game links to receive a discount when purchasing
          the game.
        </p>
        <p>
          For the first time, PES 2021 will be a season update - this means the
          game will be pretty much the same in terms of features and gameplay,
          but all squads will be updated for the 2021/2022 season.
        </p>
        <p>With the standard edition, you will get:</p>
        <ul>
          <li>2.000 coins.</li>
          <li>Lionel Messi (on loan).</li>
          <li>1 Premium Agent a week (for 10 weeks).</li>
          <li>3 Contract Renewal Tickets a week (for 10 weeks).</li>
        </ul>
        <p>
          With a Club Edition, depending on the club you pick, you will get:
        </p>
        <ul>
          <li>3.000 coins.</li>
          <li>Lionel Messi (on loan).</li>
          <li>1 Premium Agent a week (for 30 weeks).</li>
          <li>3 Contract Renewal Tickets a week (for 30 weeks).</li>
          <li>
            Full Squad (FC Barcelona, Bayern, Juventus, Manchester United or
            Arsenal)
          </li>
          <li>Icon version of Messi, Kahn, C. Ronaldo, Beckham or Bergkamp</li>
          <li>Retro club Kit</li>
          <li>Club menu theme and avatar</li>
        </ul>
        <h3>Veteran Bonus</h3>
        <p>
          If you played myClub mode in either PES 2020 or PES 2020 LITE, you
          will be eligible to receive the Veterans Bonus if you purchase the
          Season Update between 15/09/2020 and 10/12/2020. You will need to
          claim it with the same account that you used to play the 2020 game.
          You could work towards these milestones until 03/09/2020.
        </p>
        <p>
          You will get some Special Agents as rewards, depending on 2
          milestones. These Special Agents can get you Iconic Moment Series
          Players or Featured Players.
        </p>
        <ul>
          <li>
            how many Legends (Iconomic Moment Series or Legend players) you own
            (duplicates of players you own are counted toward your total number
            of players.).
          </li>
          <li>
            how much you played PES 2020 (more especifically, Matchday mode).
          </li>
        </ul>
        <p>Regarding Legends, Konami announced the following tiers:</p>
        <ul>
          <li>
            If you own 1 to 3 Legends, you will get 1x 'PES 2020-21 Iconic
            Moment Series' Special Agent.
          </li>
          <li>
            If you own 4 to 6 Legends: 2x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
          <li>
            If you own 7 to 9 Legends: 3x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
          <li>
            If you own 10 to 12 Legends: 4x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
          <li>
            If you own 13 or more Legends: 5x 'PES 2020-21 Iconic Moment Series'
            Special Agent.
          </li>
        </ul>
        <p>Regarding Matchday matches played, Konami stated the following:</p>
        <ul>
          <li>
            if you played 5 to 29 matches: 1x 'PES 2020-21 Featured Players'
            Special Agent.
          </li>
          <li>
            30 to 99 matches: 2x 'PES 2020-21 Featured Players' Special Agent.
          </li>
          <li>
            100 or more matches: 3x 'PES 2020-21 Featured Players' Special
            Agent.
          </li>
        </ul>
        <p>
          Your total number of Matchday matches can be checked in-game from the
          following location: Top Menu > Statistics > Online Stats > User
          Details > Matchday > Matches
        </p>
        <h2 id="whatsnew">What's new in PES 2021?</h2>
        <p>
          If you played the 2019 version, you will quickly realize Konami did
          not change much for eFootball Pro Evolution Soccer 2020. For 2021,
          since it is just a Season Update, the changes were pretty much
          cosmetics and squad updates, so it is more important to learn what
          changed in PES 2020.
        </p>
        <p>That said, here is everything you need to know:</p>
        <ul>
          <li>
            <Link to="/coins-gp/">eFootball Points</Link> - new currency
            introduced in PES 2021.
          </li>
          <li>
            There's a new{" "}
            <Link to="/game-modes-challenges/">Matchday Mode</Link>.
          </li>
          <li>
            We now also have Featured National Team players (not just clubs).
          </li>
          <li>
            Weekly Events and Competitions have moved from Thursdays to Mondays
            (new Featured Players are still released on Thursdays).
          </li>
          <li>
            Every player starts at level 1 now, which corresponds to the old
            level 30. This basically means every player will perform to his true
            potential from the moment you sign him.
          </li>
          <li>
            You can no longer gain GP from offline game modes (like Training,
            Master League or Become a Legend).
          </li>
          <li>
            <Link to="/legends/">Legends</Link> have been upgraded to a
            Condition Rating of "B" as of Data Pack 4.0 (13/02/2020).
          </li>
          <li>
            Konami introduced a new kind of <Link to="/legends/">Legends</Link>{" "}
            - Iconic Moment Series - in Data Pack 5.0 (05/03/2020).
          </li>
        </ul>
        <h2 id="why-season-update">
          Why a Season Update? What about next-gen?
        </h2>
        <p>
          The reason for this is simple: Konami will be working on PES 2022 with
          a new engine (Unreal Engine), promising more realistic player models,
          enhanced physics and better animations, targeted at the next-gen
          consoles (PS5 and Xbox Series X).
        </p>
        <p>
          The idea is to begin testing the new game with this engine in
          mid-2021, for a release later that year.
        </p>
        <p>
          You can have an early look at what the game will look like by watching
          the trailer below. It's not much, but it's promising!
        </p>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/PVuYuU4JQL8"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          title="Taking PES to a Whole New Level"
        ></iframe>
        <h2 id="about">About this Guide</h2>
        <p>
          I have always been a fan of Pro Evolution Soccer - ever since the
          early ISS days - but I really only gave myClub a chance in 2019.
        </p>
        <p>
          When I was just starting out, I noticed information was sparse, so
          this guide is my attempt of condensing everything you should know
          regarding PES myClub. The guide has been updated to eFootball Pro
          Evolution Soccer 2021 (Season Update).
        </p>
        <p>
          I am by no means a myClub veteran/guru, so if you would like to
          contribute, please <Link to="/contact/">contact me</Link> - I’ll be
          more than happy to credit you for your help.
        </p>
        <p>
          I would like this guide to be as complete as possible, so that
          newcomers to myClub don’t feel overwhelmed like I did.
        </p>
        <p>
          I did my best to break everything into chapters, but at times I will
          introduce new things that haven't been covered yet - if you feel
          confused, I encourage you to read further. Everything is connected in
          the game and I promise you in the end it will (hopefully!) all make
          sense :)
        </p>
        <p>
          Finally, I will avoid mentioning game controls, so hopefully this
          guide will make sense to any PS4/XBOX/PC gamer, or even PES Mobile on
          Android or iOS. The only exception is in the{" "}
          <Link to="/gameplay-tips/">Gameplay Tips </Link>section.
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CardGrid = styled.div`
  grid-column-gap: 1.5rem;
  margin-top: 1.5rem;
`

const Card = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  ul {
    text-align: left;
  }
  span {
    font-weight: 700;
    color: var(--main-black);
    display: block;
    font-size: 1.999rem;
    line-height: 2.3988rem;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }
  padding: 2rem 1.5rem 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--card-bg);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
`

const menuCards = () => {
  return (
    <CardGrid className="grid-list">
      <Card>
        <FontAwesomeIcon icon={["fad", "user-tie"]} size="3x" />
        <span>Managers</span>
        <ul>
          <li>
            <Link to="/managers/">Manager List</Link>
          </li>
          <li>
            <Link to="/signing-managers/">Signing Managers</Link>
          </li>
          <li>
            <Link to="/manager-upgrades/">Manager Upgrades</Link>
          </li>
          <li>
            <Link to="/best-managers/">Best Managers</Link>
          </li>
        </ul>
      </Card>
      <Card>
        <FontAwesomeIcon icon={["fad", "futbol"]} size="3x" />
        <span>Players</span>
        <ul>
          <li>
            <Link to="/signing-new-players-agents/">Agents</Link>
          </li>
          <li>
            <Link to="/signing-new-players-scout-combinations/">Scouts</Link>
          </li>
          <li>
            <Link to="/legends/">Legends</Link>
          </li>
          <li>
            <Link to="/best-players-ball-type/">Best Players</Link>
          </li>
        </ul>
      </Card>
      <Card>
        <FontAwesomeIcon icon={["fad", "trophy-alt"]} size="3x" />
        <span>Tips & Tricks</span>
        <ul>
          <li>
            <Link to="/myclub-tips-tricks/">myClub Tips</Link>
          </li>
          <li>
            <Link to="/gameplay-tips/">Gameplay Tips</Link>
          </li>
          <li>
            <Link to="/how-to-get-more-gp/">How to get more GP</Link>
          </li>
        </ul>
      </Card>
    </CardGrid>
  )
}

export default menuCards
